
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/kTDatatableLeft.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { VueCookieNext } from 'vue-cookie-next';
import { Form, Field, ErrorMessage } from 'vee-validate';
import { apiEndpoint } from '@/mixin/apiEndpoint.js';
import moment from 'moment';

export default defineComponent({
  name: 'Required Income Certificate',
  components: {
    Datatable,
    Field,
  },
  mixins: [apiEndpoint],
  data() {
    return {
      tranches: [] as any,
      TranceData: [],
      start_date: '',
      end_date: '',
      TranceLabel: [] as any,
      TpartnerLabel: [] as any,
      TinstituteLabel: [] as any,
      TCourseTypeLabel: [] as any,
      TCourseListLabel: [] as any,
      moment: '' as any,
      TCourseLabel: [] as any,
      TbatchesLabel: [] as any,
      selectedDistricts: [] as any,
      LicenseData: [],
      selectedTrance: [],

      licenseProvided: [
        {
          id: 1,
          name: 'Yes',
        },
        {
          id: 2,
          name: 'No',
        },
      ],
      updatedArray: [] as any,
      TpartnerData: [],
      CourseTypeData: [] as any,
      CourseListData: [] as any,
      TinstituteData: [],
      TbatchData: [],
      genderData: [] as any,
      dropout: '' as any,
      absent: '' as any,
      searchType: '' as any,
      isDropOut: '' as any,
      isAbsent: '' as any,
      tableData: [] as any,
      api_url: '',
      tableHeader: [
        {
          name: 'SL',
          key: 'sl',
          sortable: true,
          selected: true,
        },
        {
          name: 'Entity',
          key: 'association',
          sortable: true,
          selected: true,
        },
        {
          name: 'Training Institute',
          key: 't_institute',
          sortable: true,
          selected: true,
        },
        {
          name: 'Course',
          key: 'course',
          sortable: true,
          selected: true,
          width: '200px',
        },
        {
          name: 'Batch',
          key: 'batch',
          sortable: true,
          selected: true,
        },
        {
          name: 'Start Date',
          key: 'start_date',
          sortable: true,
          width: '200px',
        },
        {
          name: 'End Date',
          key: 'end_date',
          sortable: true,
          width: '200px',
        },
        {
          name: 'Registration Number',
          key: 'reg_num',
          sortable: true,
          selected: true,
          width: '200px',
        },
        {
          name: 'Reference Number',
          key: 'ref_num',
          sortable: true,
          selected: true,
          width: '200px',
        },
        {
          name: 'Name',
          key: 'trainee_name',
          sortable: true,
          selected: true,
          width: '200px',
        },
        {
          name: 'Income Certificate',
          key: 'income_certificate',
          sortable: true,
          selected: true,
        },
        {
          name: 'Home District',
          key: 'home_dist',
          sortable: true,
          selected: true,
        },
        {
          name: 'Income',
          key: 'income',
          sortable: true,
          selected: true,
        },
      ] as any,

      st: [] as any,
      instituteList: [] as any,
      courseType: [] as any,
      courseList: [] as any,
      batchList: [] as any,
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
      showTableData: false,
      showtable: true,
      showall: false,
      discardChangeData: false,
      declinechange: false,
      componentKey: 0,
      componentTableKey: 0,
      formattedHeader: [] as any,
      entityInfos: [] as any,
      formattedBody: [] as any,
      headerSelect: false,
      load: true,
      tableLoad: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.associationList();
    await this.getCourseTypeList();
    this.api_url = this.VUE_APP_API_URL;
    this.moment = moment;
  },
  methods: {
    trancheLabelChange(data) {
      // this.getCourseListData();
      this.TranceLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.tranches.length; j++) {
          if (data[i] == this.tranches[j].id) {
            this.TranceLabel.push(this.tranches[j].label);
          }
        }
      }
    },
    trainingPartnerLabelChange(data) {
      // this.getCourseListData();
      this.getInstitute();
      this.TpartnerLabel = [];
      console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.entityInfos.length; j++) {
          if (data[i] == this.entityInfos[j].id) {
            this.TpartnerLabel.push(this.entityInfos[j].entity_short_name);
          }
        }
      }
    },
    trainingInstituteLabelChange(data) {
      this.getCourseListData();

      this.TinstituteLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.instituteList.length; j++) {
          if (data[i] == this.instituteList[j].id) {
            this.TinstituteLabel.push(this.instituteList[j].short_name);
          }
        }
      }
    },
    async getCourseTypeList() {
      await ApiService.get('configurations/course_type/list')
        .then((response) => {
          this.courseType = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getCourseListData() {
      // this.load = true;
      await ApiService.get(
        'course/filterCourse?entity=' +
          this.TpartnerData +
          '&tranche=' +
          this.TranceData +
          '&courseType=' +
          this.CourseTypeData
      )
        .then((response) => {
          this.courseList = response.data.data;
          // this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    courseTypeLabelChange(data) {
      this.getCourseListData();
      this.TCourseTypeLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseType.length; j++) {
          if (data[i] == this.courseType[j].id) {
            this.TCourseTypeLabel.push(this.courseType[j].type_name);
          }
        }
      }
    },
    courseListLabelChange(data) {
      this.getBatches();
      this.TCourseListLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.courseList.length; j++) {
          if (data[i] == this.courseList[j].id) {
            this.TCourseListLabel.push(this.courseList[j].course_name);
          }
        }
      }
    },

    tbatchLabelChange(data) {
      this.TbatchesLabel = [];
      //console.log(data);
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.batchList.length; j++) {
          if (data[i] == this.batchList[j].id) {
            this.TbatchesLabel.push(this.batchList[j].batch_sequence_number);
          }
        }
      }
    },
    resetData() {
      this.tableData = [] as any;
      this.st = [] as any;
      (this.TranceLabel = [] as any),
        (this.TpartnerLabel = [] as any),
        (this.TinstituteLabel = [] as any),
        (this.TCourseTypeLabel = [] as any),
        (this.TCourseListLabel = [] as any),
        (this.TCourseLabel = [] as any),
        (this.TbatchesLabel = [] as any),
        (this.TpartnerData = [] as any),
        (this.CourseTypeData = [] as any),
        (this.CourseListData = [] as any),
        (this.TinstituteData = [] as any),
        (this.TbatchData = [] as any),
        (this.genderData = [] as any),
        (this.TranceData = [] as any),
        (this.showall = false);
      this.showTableData = false;
      this.componentTableKey += 1;
    },
    async generateIncomeCertificateReport() {
      this.tableLoad = true;
      await ApiService.get(
        'trainee/required-certificate?entity=' +
          this.TpartnerData +
          '&tranche=' +
          this.TranceData +
          '&course=' +
          this.CourseListData +
          '&institute=' +
          this.TinstituteData +
          '&batch=' +
          this.TbatchData +
          '&start_date=' +
          this.start_date +
          '&end_date=' +
          this.end_date
      )
        .then((response) => {
          this.headerSelect = true;
          this.selectedTableHeader();
          this.tableData = response.data.data;
          this.componentTableKey += 1;
          this.showTableData = true;
          this.tableLoad = false;
        })
        .then((response) => {
          console.log(response);
        });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      this.load = true;
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      // console.log(entity_id);
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getInstitute() {
      // this.load = true;
      let institute_info_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
        institute_info_id = VueCookieNext.getCookie('_institute_info_id');
      }
      await ApiService.get(
        this.VUE_APP_INSTITUTE_LIST_API +
          '?entity_id=' +
          this.TpartnerData +
          '&institute_info_id=' +
          institute_info_id
      )
        .then((response) => {
          console.log(response);
          this.instituteList = response.data.data;
          // this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getBatches() {
      // this.load = true;
      await ApiService.get(
        'batch/multiple-batch-filter?tranche=' +
          this.TranceData +
          '&entity=' +
          this.TpartnerData +
          '&institute=' +
          this.TinstituteData +
          '&course=' +
          this.CourseListData
      )
        .then((response) => {
          console.log(response);
          this.batchList = response.data.data;
          // this.load = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    async getData() {
      if (this.TpartnerData.length || this.TranceData.length) {
        await ApiService.get(
          'report/motor-driving-batch?tranche=' +
            this.TranceData +
            '&entity=' +
            this.TpartnerData +
            '&institute=' +
            this.TinstituteData +
            '&batch=' +
            this.TbatchData +
            '&start_date=' +
            this.start_date +
            '&end_date=' +
            this.end_date
        )
          .then((response) => {
            this.headerSelect = true;
            this.selectedTableHeader();
            this.tableData = response.data.data;
            this.componentTableKey += 1;
          })
          .then((response) => {
            console.log(response);
          });
      } else {
        Swal.fire({
          title: 'Error Found',
          text: 'Tranche or Training Partner Required',
          icon: 'error',
          buttonsStyling: false,
          confirmButtonText: 'Close',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        });
      }
    },

    selectedTableHeader() {
      this.st = Array();

      for (let i = 0; i < this.tableHeader.length; i++) {
        if (this.tableHeader[i].selected) {
          this.st.push(JSON.parse(JSON.stringify(this.tableHeader[i])));
          // console.log(this.st);
        }
      }
      this.showTableData = true;
      this.showall = true;
    },
  },

  // beforeMount() {
  //   this.selectedTableHeader();
  // },
  computed: {},
  setup() {},
});
